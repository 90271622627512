import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

function MessageCard({ title, text }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>{text}</CardContent>
      <CardActions />
    </Card>
  );
}

MessageCard.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MessageCard;
