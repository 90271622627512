import React from 'react';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { firestore } from '../../firebase';

import AuthUserContext from '../../components/Session';

import OrderItem from './Order';
import Resume from './Resume';
import UpdateButton from './UpdateButton';
import OfferForm from './OfferForm';

function TabContent({ list, message }) {
  if (list.length === 0) {
    return (
      <Grid container justify="center" style={{ marginTop: 8 }}>
        <ArrowDownwardIcon />
        <Typography align="center">{message}</Typography>
      </Grid>
    );
  }

  return list.map(item => <OrderItem key={item.id} {...item} />);
}

function Order() {
  const authUser = React.useContext(AuthUserContext);
  const [orders, setOrders] = React.useState([]);
  const [offer, setOffer] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const [modeView, setModeView] = React.useState('list');

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  const handleUpdateOfferClick = () => {
    setModeView('form');
  };

  const handleCloseForm = () => {
    setModeView('list');
  };

  React.useEffect(() => {
    if (offer) {
      return firestore
        .collection('orders')
        .where('offerId', '==', offer.id)
        .onSnapshot(querySnapshot => {
          const list = [];

          querySnapshot.forEach(doc => {
            const { userName, code, quantity, createdAt, value, pickedAt } = doc.data();

            list.push({
              id: doc.id,
              userName,
              code,
              quantity,
              createdAt,
              value,
              pickedAt,
            });
          });

          setOrders(list);
        });
    }
  }, [offer]);

  React.useEffect(() => {
    return firestore
      .collection('offers')
      .where('restaurantId', '==', authUser.id)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .onSnapshot(querySnapshot => {
        if (querySnapshot && querySnapshot.size > 0) {
          const {
            quantity,
            price,
            discount,
            startTime,
            endTime,
            status,
            booked,
          } = querySnapshot.docs[0].data();

          setOffer({
            id: querySnapshot.docs[0].id,
            quantity,
            price,
            discount,
            startTime: startTime.toDate(),
            endTime: endTime.toDate(),
            status,
            booked,
          });
        }
      });
  }, [authUser.id]);

  const offered = offer ? offer.quantity : null;
  const sold = offer ? offer.booked : null;
  const available = offer ? offer.quantity - orders.length : null;

  const ordersAvailable = orders.filter(o => !o.pickedAt);
  const ordersCompleted = orders.filter(o => !!o.pickedAt);

  return (
    <Container maxWidth="md">
      {modeView === 'list' && (
        <>
          <Resume
            offered={offered}
            sold={sold}
            available={available}
            unavailable={offer && offer.status === 2}
          />
          <UpdateButton
            onClick={handleUpdateOfferClick}
            title={offer && offer.status === 1 ? 'Atualizar Oferta' : 'Criar Oferta'}
          />
          <Hidden mdUp>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={handleTabChange}
              aria-label="abas de pedidos"
              variant="fullWidth"
            >
              <Tab id="aba-pedidos-ativos" label={`Ativos (${ordersAvailable.length})`} />
              <Tab id="aba-pedidos-completos" label={`Completos (${ordersCompleted.length})`} />
            </Tabs>
            {tab === 0 && (
              <TabContent
                list={ordersAvailable}
                message="Depois que uma compra é feita, ele aparece aqui"
              />
            )}
            {tab === 1 && (
              <TabContent
                list={ordersCompleted}
                message="Quando um pedido é retirado, ele aparece aqui"
              />
            )}
          </Hidden>
          <Hidden smDown>
            <Grid container spacing={3}>
              <Grid container direction="column" item md={6}>
                <Grid item style={{ padding: 16, borderBottom: '1px solid #3eb397' }}>
                  <Typography
                    variant="button"
                    color="primary"
                    align="center"
                    display="block"
                  >{`ATIVOS (${ordersAvailable.length})`}</Typography>
                </Grid>
                <Grid item>
                  <TabContent
                    list={ordersAvailable}
                    message="Depois que uma compra é feita, ele aparece aqui"
                  />
                </Grid>
              </Grid>
              <Grid container direction="column" item md={6}>
                <Grid item style={{ padding: 16, borderBottom: '1px solid #3eb397' }}>
                  <Typography
                    variant="button"
                    color="primary"
                    align="center"
                    display="block"
                  >{`COMPLETOS (${ordersCompleted.length})`}</Typography>
                </Grid>
                <Grid item>
                  <TabContent
                    list={ordersCompleted}
                    message="Quando um pedido é retirado, ele aparece aqui"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </>
      )}
      {modeView === 'form' && (
        <OfferForm
          initialValues={offer && offer.status === 1 ? offer : undefined}
          close={handleCloseForm}
        />
      )}
    </Container>
  );
}

export default Order;
