import React from 'react';
import Container from '@material-ui/core/Container';

function SettingsPage() {
  return (
    <Container>
      <h1>Configurações</h1>
    </Container>
  );
}

export default SettingsPage;
