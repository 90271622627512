import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AccountCircle from '@material-ui/icons/AccountCircle';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MenuIcon from '@material-ui/icons/Menu';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';

import { auth, firestore } from '../../firebase';

import AuthUserContext from '../../components/Session';
import PrivateRoute from '../../components/PrivateRoute';

import Account from '../../routes/Account';
import DashBoard from '../../routes/DashBoard';
import Help from '../../routes/Help';
import Order from '../../routes/Order';
import Payment from '../../routes/Payment';
import Settings from '../../routes/Settings';

import ListItemLink from './ListItemLink';
import Profile from './Profile';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function BaseLayout() {
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [authUser, setAuthUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoutClick = () => {
    auth.signOut().then(() => setAuthUser(null));
  };

  React.useEffect(() => {
    return auth.onAuthStateChanged(credentials => {
      if (credentials) {
        firestore
          .collection('restaurants')
          .doc(credentials.uid)
          .get()
          .then(doc => {
            setAuthUser({
              id: doc.id,
              ...doc.data(),
            });

            setLoading(false);
          });
      } else {
        setAuthUser(null);
        setLoading(false);
      }
    });
  }, []);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Profile />
      <Divider />
      <List>
        <ListItemLink primary="Oferta" to="/orders" icon={<LocalOfferIcon />} />
        <ListItemLink primary="Estatísticas" to="/dashboard" icon={<BarChartIcon />} />
      </List>
      <Divider />
      <List>
        <ListItemLink primary="Pagamento" to="/payment" icon={<PaymentIcon />} />
        <ListItemLink primary="Configurações" to="/settings" icon={<SettingsIcon />} />
        <ListItemLink primary="Conta" to="/account" icon={<AccountCircle />} />
        <ListItemLink primary="Ajuda" to="/help" icon={<LiveHelpIcon />} />
      </List>
      <Divider />
      <ListItem onClick={handleLogoutClick} button>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </div>
  );

  return (
    <AuthUserContext.Provider value={authUser}>
      {loading && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/dashboard" component={DashBoard} />
            <PrivateRoute path="/help" component={Help} />
            <PrivateRoute path="/orders" component={Order} />
            <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/settings" component={Settings} />
          </main>
        </div>
      )}
    </AuthUserContext.Provider>
  );
}

export default BaseLayout;
