import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';
import mergeFormikProps from '../mergeFormikProps';

function Select({ value, label, options, fullWidth, required, helperText, ...restProps }) {
  return (
    <MuiFormControl fullWidth={fullWidth} required={required}>
      <MuiInputLabel id={`${label}-select-label`}>{label}</MuiInputLabel>
      <MuiSelect
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        {...restProps}
      >
        {options.map(o => (
          <MuiMenuItem key={o.value} value={o.value}>
            {o.label}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <MuiFormHelperText id={`${label}-select-label`}>{helperText}</MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}

Select.propTypes = {
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  required: PropTypes.bool,
  value: PropTypes.number.isRequired,
};

Select.defaultProps = {
  fullWidth: false,
  helperText: undefined,
  required: false,
};

export default mergeFormikProps(Select);
