import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { formatCurrency } from '../../utils';
import { firestore } from '../../firebase';
import AuthUserContext from '../../components/Session';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingBottom: 8,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    tile: { alignItems: 'center' },
    numberText: { fontSize: 40 },
    descText: { fontSize: 12, color: '#8a8a8a' },
    avatar: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      marginRight: theme.spacing(2),
    },
  }),
);

function DashBoard() {
  const classes = useStyles();
  const authUser = React.useContext(AuthUserContext);

  const [loading, setLoading] = React.useState(true);
  const [restaurant, setRestaurant] = React.useState();

  React.useEffect(() => {
    firestore
      .collection('restaurants')
      .doc(authUser.id)
      .onSnapshot(doc => {
        setRestaurant({
          id: doc.id,
          ...doc.data(),
        });

        if (loading) {
          setLoading(false);
        }
      });
  }, [authUser.id, loading]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="row"
          alignItems="center"
          justify="center"
          alignContent="center"
        >
          <Avatar className={classes.avatar} src="/logo192.svg" />
          <Grid item>
            <Typography variant="h5">Bem-vindo</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="body2" align="center" style={{ color: '#8a8a8a' }}>
                    Comece utilizar o Salvoo criando uma nova oferta. Enviaremos uma notificação por
                    push aos usuários do Salvoo na sua área, informando que há comida excelente para
                    resgatar. Assista suas vendas crescerem!
                  </Typography>
                </Grid>
                <Grid item xs container direction="column" alignItems="center">
                  <Typography className={classes.numberText}>
                    {restaurant?.favoritesCount || 0}
                  </Typography>
                  <Typography className={classes.descText}>Seguidores</Typography>
                </Grid>
                <Grid item xs container direction="column" alignItems="center">
                  <Typography className={classes.numberText}>
                    {restaurant?.rescuedMeal || 0}
                  </Typography>
                  <Typography className={classes.descText}>Itens Resgatados</Typography>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  alignItems="center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Typography className={classes.numberText}>
                    {formatCurrency(restaurant?.generatedRevenue || 0)}
                  </Typography>
                  <Typography className={classes.descText}>Receita Gerada</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs container alignItems="center">
                  <Typography variant="body2" align="center" style={{ color: '#8a8a8a' }}>
                    Quando você inicia uma venda, uma notificação será enviada aos seus seguidores
                    no aplicativo!
                  </Typography>
                </Grid>
                <Grid item xs container direction="column" alignItems="center">
                  <Typography className={classes.numberText}>0</Typography>
                  <Typography className={classes.descText}>
                    Notificações nos ultimos 7 dias
                  </Typography>
                </Grid>
                <Grid item xs container direction="column" alignItems="center">
                  <Typography className={classes.numberText}>0</Typography>
                  <Typography className={classes.descText}>Total de notificações</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DashBoard;
