import React from 'react';
import Container from '@material-ui/core/Container';

function PaymentPage() {
  return (
    <Container>
      <h1>Pagamento</h1>
    </Container>
  );
}

export default PaymentPage;
