import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    tile: { alignItems: 'center' },
    numberText: { fontSize: 48 },
    descText: { fontSize: 12 },
    unavailableText: { fontSize: 20, fontWeight: 'bold' },
  }),
);

function Resume({ offered, sold, available, unavailable }) {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      {unavailable && (
        <Grid item container justify="center">
          <Typography color="secondary" className={classes.unavailableText}>
            OFERTA ENCERRADA
          </Typography>
        </Grid>
      )}
      <Grid item container>
        <Grid item xs container direction="column" alignItems="center">
          <Typography className={classes.numberText}>{offered || '-'}</Typography>
          <Typography className={classes.descText}>OFERTADOS</Typography>
        </Grid>
        <Grid item xs container direction="column" alignItems="center">
          <Typography className={classes.numberText}>{sold || '-'}</Typography>
          <Typography className={classes.descText}>VENDIDOS</Typography>
        </Grid>
        <Grid item xs container direction="column" alignItems="center">
          <Typography className={classes.numberText}>{available || '-'}</Typography>
          <Typography className={classes.descText}>DISPONÍVEIS</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Resume.propTypes = {
  available: PropTypes.number,
  offered: PropTypes.number,
  sold: PropTypes.number,
  unavailable: PropTypes.bool.isRequired,
};

Resume.defaultProps = {
  available: '-',
  offered: '-',
  sold: '-',
};

export default Resume;
