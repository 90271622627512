import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: `${process.env.REACT_APP_PROJECT}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_PROJECT}.firebaseio.com`,
  projectId: process.env.REACT_APP_PROJECT,
  storageBucket: `${process.env.REACT_APP_PROJECT}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_MESSAGING,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT,
};

firebase.initializeApp(config);

export default firebase;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const performance = firebase.performance();
