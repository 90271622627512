import React from 'react';
import Container from '@material-ui/core/Container';
import AuthUserContext from '../../components/Session';

function AccountPage() {
  const authUser = React.useContext(AuthUserContext);

  return (
    <Container>
      <h1>Account: {authUser.email}</h1>
    </Container>
  );
}

export default AccountPage;
