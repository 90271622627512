import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import mergeFormikProps from '../mergeFormikProps';

const styles = () => ({
  readOnly: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

const TextField = ({ classes, disabled, readOnly, InputProps, ...props }) => (
  <MuiTextField
    InputProps={{
      ...InputProps,
      classes: {
        disabled: clsx(
          { [classes.readOnly]: readOnly },
          { [classes.disabled]: disabled },
          InputProps && InputProps.classes ? InputProps.classes.disabled : null,
        ),
      },
    }}
    disabled={readOnly || disabled}
    {...props}
  />
);

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  InputProps: null,
  readOnly: false,
};

export default mergeFormikProps(withStyles(styles)(TextField));
