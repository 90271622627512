import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { isAfter, isEqual } from 'date-fns';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import AuthUserContext from '../../components/Session';
import firebase, { firestore } from '../../firebase';
import { IntegerField, CurrencyField, Select, TimePicker } from '../../components/inputs';
import { formatCurrency } from '../../utils';

function roundTimeHour(interval = 0) {
  const timeToReturn = new Date();

  timeToReturn.setMilliseconds(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes((Math.round(timeToReturn.getMinutes() / 10) + interval) * 10);

  return timeToReturn;
}

function OfferForm({ initialValues, close }) {
  const authUser = React.useContext(AuthUserContext);

  const validateField = value => !value && value !== 0;

  async function updateOffer(values) {
    const restaurant = await firestore
      .collection('restaurants')
      .doc(authUser.id)
      .get()
      .then(query => ({ id: query.id, ...query.data() }));

    const restaurantInfo = {
      restaurantId: restaurant.id,
      avatarUrl: restaurant.avatarUrl,
      coverUrl: restaurant.coverUrl,
      restaurantAddress: restaurant.address,
      restaurantName: restaurant.name,
      restaurantDesc: restaurant.description,
      location: restaurant.location,
    };

    if (values.id) {
      await firestore
        .collection('offers')
        .doc(values.id)
        .update({
          ...restaurantInfo,
          quantity: values.quantity,
          price: values.price,
          discount: values.discount,
          startTime: firebase.firestore.Timestamp.fromDate(values.startTime),
          endTime: firebase.firestore.Timestamp.fromDate(values.endTime),
        });
    } else {
      await firestore.collection('offers').add({
        ...restaurantInfo,
        quantity: values.quantity,
        price: values.price,
        discount: values.discount,
        startTime: firebase.firestore.Timestamp.fromDate(values.startTime),
        endTime: firebase.firestore.Timestamp.fromDate(values.endTime),
        booked: 0,
        status: 1,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    close();
  }

  async function closeOffer(setSubmitting) {
    if (initialValues.id) {
      setSubmitting(true);

      await firestore
        .collection('offers')
        .doc(initialValues.id)
        .update({ status: 2 });

      setSubmitting(false);

      close();
    }
  }

  return (
    <Paper style={{ padding: 24 }}>
      <Typography variant="h3" color="primary" style={{ marginBottom: 16 }}>
        {initialValues.id ? 'Atualizar' : 'Criar'} Oferta
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={updateOffer}
        validate={values => {
          const errors = {};

          if (!values.quantity) {
            errors.quantity = 'Informe uma quantidade';
          } else if (values.quantity === 0) {
            errors.quantity = 'Quantidade deve ser maior que 0';
          }

          if (!values.price) {
            errors.price = 'Informe um preço';
          } else if (values.price === 0) {
            errors.price = 'Preço deve ser maior que R$ 0.00';
          }

          if (
            !isEqual(values.startTime, initialValues.startTime) &&
            !isAfter(values.startTime, new Date())
          ) {
            errors.startTime = 'Hora inicial tem que ser maior que atual';
          }

          if (!isAfter(values.endTime, values.startTime)) {
            errors.endTime = 'Hora final tem que ser maior que inicial';
          }

          return errors;
        }}
      >
        {({ setSubmitting, isSubmitting, values, errors, touched }) => {
          const isValid =
            !errors ||
            !touched ||
            !Object.keys(errors).some(e => Object.keys(touched).indexOf(e) >= 0);

          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="startTime"
                    label="início do Período de Retirada"
                    validate={validateField}
                    component={TimePicker}
                    minutesStep={10}
                    errorText={errors.startTime}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="endTime"
                    label="Fim do Período de Retirada"
                    validate={validateField}
                    component={TimePicker}
                    minutesStep={10}
                    errorText={errors.endTime}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="quantity"
                    label="Quantidade"
                    component={IntegerField}
                    errorText={errors.quantity}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="price"
                    label="Preço"
                    component={CurrencyField}
                    errorText={errors.price}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="discount"
                    label="Desconto"
                    component={Select}
                    options={[
                      { value: 0.5, label: '50%' },
                      { value: 0.55, label: '55%' },
                      { value: 0.6, label: '60%' },
                      { value: 0.65, label: '65%' },
                      { value: 0.7, label: '70%' },
                      { value: 0.75, label: '75%' },
                      { value: 0.8, label: '80%' },
                      { value: 0.85, label: '85%' },
                      { value: 0.9, label: '90%' },
                      { value: 0.95, label: '95%' },
                      { value: 1.0, label: '100%' },
                    ]}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} container justify="center">
                  <div
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 36,
                      padding: 16,
                    }}
                  >
                    <Typography display="inline">Preço no Salvoo:</Typography>
                    <Typography display="inline" style={{ marginLeft: 150 }}>
                      {formatCurrency(values.price - values.price * values.discount)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    style={{ marginRight: 8 }}
                  >
                    {initialValues.id ? 'Atualizar' : 'Criar'}
                  </Button>
                  {values.id && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => closeOffer(setSubmitting)}
                      disabled={isSubmitting}
                      style={{ marginRight: 8 }}
                    >
                      Encerrar
                    </Button>
                  )}
                  <Button variant="contained" onClick={close} disabled={isSubmitting}>
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

OfferForm.propTypes = {
  close: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    discount: PropTypes.number.isRequired,
    endTime: PropTypes.object,
    id: PropTypes.number,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    startTime: PropTypes.object,
  }),
};

OfferForm.defaultProps = {
  initialValues: {
    quantity: 0,
    price: 0,
    discount: 0.5,
    startTime: roundTimeHour(1),
    endTime: roundTimeHour(2),
  },
};

export default OfferForm;
