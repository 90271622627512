export default function formatCurrency(value) {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof value !== 'number') {
      throw new Error('Material-UI: formatCurrency(value) expects a Number argument.');
    }
  }

  const newValue = value.toFixed(2);

  return `R$ ${newValue.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
}
