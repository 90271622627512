import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import PasswordField from './PasswordField';
import MessageCard from './MessageCard';

import { auth } from '../../firebase';

const messages = {
  resetPasswordDone: {
    title: 'Senha alterada',
    text: 'Faça login com a sua nova senha',
  },
  usedOrExpiredCode: {
    title: 'Tente redefinir sua senha novamente',
    text: 'Sua solicitação para redefinir a senha expirou ou o link já foi usado',
  },
};

function ResetPassword({ actionCode }) {
  const [email, setEmail] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [message, setMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    auth
      .verifyPasswordResetCode(actionCode)
      .then(setEmail)
      .catch(error => {
        if (error.code === 'auth/invalid-action-code') {
          setMessage('usedOrExpiredCode');
        }
      })
      .finally(() => setLoading(false));
  }, [actionCode]);

  const handleNewPassword = event => {
    setNewPassword(event.target.value);
  };

  function handleResetPassword() {
    auth.confirmPasswordReset(actionCode, newPassword).then(() => setMessage('resetPasswordDone'));
  }

  if (loading) {
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      {message ? (
        <MessageCard {...messages[message]} />
      ) : (
        <Card>
          <CardHeader title="Redefinir sua senha" />
          <CardContent>
            para <strong>{email}</strong>
            <PasswordField value={newPassword} onChange={handleNewPassword} fullWidth />
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" color="primary" onClick={handleResetPassword}>
              Salvar
            </Button>
          </CardActions>
        </Card>
      )}
    </Container>
  );
}

ResetPassword.propTypes = {
  actionCode: PropTypes.string.isRequired,
};

export default ResetPassword;
