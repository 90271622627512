import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ResetPassword from './ResetPassword';

function Auth({ location }) {
  const parsed = queryString.parse(location.search);

  switch (parsed.mode) {
    case 'resetPassword':
      return <ResetPassword actionCode={parsed.oobCode} />;
    case 'recoverEmail':
    case 'verifyEmail':
    default:
      return null;
  }
}

Auth.propTypes = {
  location: PropTypes.string.isRequired,
};

export default Auth;
