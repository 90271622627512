import React from 'react';
import PropTypes from 'prop-types';

function resolveDotNotation(notation, obj) {
  return notation.split('.').reduce((acc, cur) => (acc ? acc[cur] : acc), obj);
}

function mergeFormikProps(WrappedComponent) {
  const WrapperComponent = ({
    field,
    form,
    onChange,
    stopPropagation,
    errorText,
    ...restProps
  }) => {
    const { touched, errors } = form || restProps;

    const isTouched = field ? resolveDotNotation(field.name, touched) : false;
    const hasError = field ? resolveDotNotation(field.name, errors) : false;
    const error = field ? Boolean(isTouched && hasError) : null;

    const handleChange = value => {
      if (onChange) {
        onChange(value);
      }

      if (field && !stopPropagation) {
        field.onChange(value);
      }
    };

    return (
      <WrappedComponent
        {...field}
        error={error}
        helperText={error ? errorText : undefined}
        onChange={handleChange}
        {...restProps}
      />
    );
  };

  WrapperComponent.propTypes = {
    errorText: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]),
    }),
    form: PropTypes.shape({
      errors: PropTypes.object.isRequired,
      touched: PropTypes.object.isRequired,
    }),
    onChange: PropTypes.func,
    stopPropagation: PropTypes.bool,
  };

  WrapperComponent.defaultProps = {
    errorText: undefined,
    field: null,
    form: null,
    onChange: null,
    stopPropagation: false,
  };

  return WrapperComponent;
}

export default mergeFormikProps;
