import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme =>
  createStyles({
    container: { backgroundColor: '#000', opacity: 0.7 },
    item: { backgroundColor: theme.palette.primary.main },
    title: { color: theme.palette.primary.contrastText },
  }),
);

function UpdateItem({ onClick, title }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ListItem button onClick={onClick} className={classes.item}>
        <ListItemText primary={title} className={classes.title} />
        <ChevronRightIcon className={classes.title} />
      </ListItem>
    </div>
  );
}

UpdateItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default UpdateItem;
