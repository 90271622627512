import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '../../utils';

function Order({ userName, code, quantity, createdAt, value }) {
  return (
    <Grid container style={{ padding: 8 }}>
      <Grid item xs={8}>
        <Typography>{userName}</Typography>
        <Typography variant="caption">{code}</Typography>
      </Grid>
      <Grid item xs={4} container alignItems="center">
        <Grid item xs={2}>
          <Typography variant="h6">{quantity}</Typography>
        </Grid>
        <Grid item xs={10} container direction="column">
          <Grid item>
            <Typography align="right">{format(createdAt.toDate(), 'HH:mm')}</Typography>
          </Grid>
          <Grid item>
            <Typography align="right">{formatCurrency(value)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Order.propTypes = {
  code: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default React.memo(Order);
