import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardTimePicker as MuiKeyboardTimePicker } from '@material-ui/pickers';

const TimePicker = ({ field, form, error, errorText, ...props }) => {
  const { name, onChange } = field || props;
  const { touched, errors } = form || props;

  const showError = field ? Boolean(touched[name] && errors[name]) : !!error;

  const onValueChange = value => {
    onChange({
      target: {
        value,
        name,
      },
    });
  };

  const handleBlur = () => {
    // Work around to touch field only after value been setted. Used when user click on button to select time
    setTimeout(
      () =>
        field.onBlur({
          target: {
            name,
          },
        }),
      150,
    );
  };

  return (
    <MuiKeyboardTimePicker
      {...props}
      {...field}
      onChange={onValueChange}
      onClose={handleBlur}
      error={showError}
      helperText={showError ? errorText : undefined}
    />
  );
};

TimePicker.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    submitCount: PropTypes.number.isRequired,
    touched: PropTypes.object.isRequired,
  }),
};

TimePicker.defaultProps = {
  error: false,
  errorText: undefined,
  field: null,
  form: null,
};

export default TimePicker;
