import React from 'react';
import 'date-fns';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import BaseLayout from '../../layouts/BaseLayout';

import Auth from '../../routes/Auth';
import SignIn from '../../routes/SignIn';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3eb397',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e8476f',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={[
                '/offers',
                '/orders',
                '/dashboard',
                '/payment',
                '/settings',
                '/account',
                '/help',
              ]}
            >
              <BaseLayout />
            </Route>
            <Route path="/signin" component={SignIn} />
            <Route path="/auth/action" component={Auth} />
            <Route path="/" component={() => <Redirect to="/dashboard" />} />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
