import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import AuthUserContext from '../Session';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={innerProps => (
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <Component {...innerProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                  state: { from: innerProps.location },
                }}
              />
            )
          }
        </AuthUserContext.Consumer>
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
