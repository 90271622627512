import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

import AuthUserContext from '../../components/Session';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 100,
    height: 100,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

function Profile() {
  const authUser = React.useContext(AuthUserContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={authUser ? authUser.avatarUrl : ''}
        to="/settings"
      />
      <Typography className={classes.name} variant="h6">
        {authUser ? authUser.name : ''}
      </Typography>
    </div>
  );
}

export default Profile;
